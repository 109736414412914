@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap");
 :root{
     --header-height: 3rem;
    --nav-width: 70px;
    /* --nav-width: 200px; */
    --first-color: #3b7d3c ;
    --first-color-light: #6eb446;
    /* --first-color: #4723D9;
    --first-color-light: #AFA5D9; */
    --nav-link-color: #fff;
    --nav-link-color-hover: #101212;
    --white-color: #F7F6FB;
    --body-font: 'Nunito', sans-serif;
    --normal-font-size: 1rem;
    --z-fixed: 100
}
*,::before,::after{
    box-sizing: border-box
}
 body{
    background-color: --first-color-light!important;
    position: relative;
    margin: var(--header-height) 0 0 0;
    padding: 0 1rem;
     font-family: var(--body-font);
    font-size: var(--normal-font-size);
    transition: .5s;
    /* position: relative; */
}


a{
    text-decoration: none
}
 .header{
    width: 100%;
    height: var(--header-height);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    background-color: var(--white-color);
    z-index: var(--z-fixed);
    transition: .5s
}
 .header_toggle{
    color: var(--first-color);
    font-size: 1.5rem;
    cursor: pointer
}
 .header_img{
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden
}
 .header_img img{
    width: 40px
}
 .l-navbar{
    position: fixed;
    top: 0;
    left: -30%;
    width: var(--nav-width);
    height: 100vh;
    background-color: var(--first-color);
    padding: .5rem 1rem 0 0;
    transition: .5s;
    z-index: var(--z-fixed);
    border-left: .5rem #6eb446 solid;
}
 .nav{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
}
 .nav_logo{
    display: grid;
    grid-template-columns: max-content max-content;
    align-items: center;
    column-gap: 1rem;
    padding: .5rem 0 .5rem 1.5rem
}
 .nav_logo{
    margin-bottom: 1rem
}
 .nav_logo-icon{
    font-size: 1.25rem;
    color: var(--white-color)
}
 .nav_logo-name{
    color: var(--white-color);
    font-weight: 700
}
 .nav_link{
    text-decoration: none;
    position: relative;
    color: var(--nav-link-color);
    /* margin-bottom: 1.5rem; */
    transition: .3s;

    display: flex;
    align-items: center;
    /* column-gap: 1rem; */
    padding: .3rem 0 .5rem 1.5rem
    /* padding: .5rem 0 .5rem 1.5rem */
}
 .nav_link:hover{
    color: var(--nav-link-color-hover)
}
 .nav_icon{
    font-size: 1.25rem
}
 .nav_show{
    left: 0
}
 .body-pd{
    padding-left: calc(var(--nav-width) + 1rem)
}
 .body-pd2{
    padding-left: calc(var(--nav-width) + 1rem)
}
 .active{
    color: var(--white-color)
}
 .active::before{
    content: '';
    position: absolute;
    left: 0;
    width: 2px;
    height: 32px;
    background-color: var(--white-color)
}
 .subactive{
    color: var(--white-color)
}
 .subactive::before{
    content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%28255,255,255,1%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
    position: absolute;
    left: 0;
    /* background-color: var(--white-color) */
}
 .height-100{
    height:100vh
}
@media screen and (min-width: 768px){
    body{
        margin: calc(var(--header-height) + 1rem) 0 0 0;
        padding-left: calc(var(--nav-width) + 2rem)
    }
     .header{
        /* height: calc(var(--header-height) + 1rem); */
        height: calc(var(--header-height));
        padding: 0 2rem 0 calc(var(--nav-width) + 2rem)
    }
     .header_img{
        width: 40px;
        height: 40px
    }
     .header_img img{
        width: 45px
    }
     .l-navbar{
        left: 0;
        padding: .5rem 1rem 0 0;
    }
     .nav_show{
        width: calc(var(--nav-width) + 100px)
    }
     .body-pd{
        padding-left: calc(var(--nav-width) + 118px)
    }

    .body-pd2{
        padding-left: calc(var(--nav-width) + 1rem)
    }
}