
body{
  font-family: 'Noto Sans'!important;
}

:root{
  --bg-bd-primary: #6eb446;
  --bg-bd-primary-dark: #3b7d3c;
  --bg-bd-primary-light: #3b7d3c82;
  
}
/* .hidingHeader {
	position: relative;
	--hidingHeader-height: auto;
	--hidingHeader-bounds-height: auto;
	--hidingHeader-animation-offset: 0px;
	z-index: 10;
	height: var(--hidingHeader-bounds-height);
	margin-bottom: calc(
		var(--hidingHeader-height) - var(--hidingHeader-bounds-height)
	);
	pointer-events: none;
}

.hidingHeader-in {
	position: relative;
	position: sticky;
	top: 0;
	pointer-events: auto;
	transition: transform 0.2s;
	transform: translateY(var(--hidingHeader-animation-offset));
}

@media (prefers-reduced-motion) {
	.hidingHeader-in {
		transition-duration: 1ms;
		transition-timing-function: step-end;
	}
} */

.btn-toggle::before{
    width: 1.25em;
    line-height: 0;
    /* font-family: "Font Awesome 5 Free";
    content: '\f054'; */
    /* content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e"); */
    content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%28255,255,255,1%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
    transition: transform .35s ease;
    transform-origin: .5em 50%;
    color: white!important;
}

.btn-toggle[aria-expanded="true"]::before {
  transform: rotate(90deg);
}

.dev-qa{
  z-index: 9999;
  left: 40%;
  top: 0;
}

@media (min-width: 768px) {
  .dev-qa{
    left: 40%;
  }
  
}


.main{
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  margin: 0 auto;
  width: 100%;
  /* d-flex flex-column min-vh-100 */
  flex-direction: column;
  min-height: 100vh;
}

.bg-main{
  background-color: #282c34;
}

.my-color{
  background-color: var(--bs-success-border-subtle)!important;
}

.bg-gradient-reverse{
  background-image: linear-gradient(360deg, rgba(255,255,255,0.15), rgba(255,255,255,0));
}

.hache-100{
  height: 100%!important;
  max-height: 100%!important;
}

.pico{
  position: relative;
  display: inline-block;
  z-index: 1;
}

.pico::before{
  font-family: "Font Awesome 6 Free";
  content: '\f0d7';
  font-weight: 900;
  font-size: 2rem;
  color: var(--bs-card-cap-bg);
  display: block;
  position: absolute;
  top: 1.2rem;
  right: 50%;
  z-index: 2!important;
}

/* .best-value::before{
  font-family: "Font Awesome 6 Free";
  content: '\e5d2';
  font-weight: 900;
  font-size: 2rem;
  color: rgba(0,0,0,1);
  display: block;
  position: absolute;
  top: 0;
  right: 10;
  z-index: 0!important;
} */

.border-icon{
    text-shadow: 0 0 3px #000!important;
}

.btn-shadow{
    -webkit-box-shadow: -3px 3px 1px 1px var(--bs-emphasis-color);
    -moz-box-shadow: -3px 3px 1px 1px var(--bs-emphasis-color);
    box-shadow: -3px 3px 1px 1px var(--bs-emphasis-color);
    /* -webkit-box-shadow: -4px 4px 1px 1px rgba(0,0,0,0.75);
    -moz-box-shadow: -4px 4px 1px 1px rgba(0,0,0,0.75);
    box-shadow: -4px 4px 1px 1px rgba(0,0,0,0.75); */
}

.card-shadow{
    -webkit-box-shadow: -3px 3px 5px 1px rgba(171,168,171,1);
    -moz-box-shadow: -3px 3px 5px 1px rgba(171,168,171,1);
    box-shadow: -3px 3px 5px 1px rgba(171,168,171,1);
}

/*Custom*/
.btn-bd-primary {
  --bs-btn-color: var(--bs-white)!important;
  --bs-btn-bg: var(--bg-bd-primary)!important;
  --bs-btn-border-color: var(--bg-bd-primary)!important;
  --bs-btn-border-radius: .5rem!important;
  --bs-btn-hover-color: var(--bs-white)!important;
  --bs-btn-hover-bg: var(--bg-bd-primary-dark)!important;
  --bs-btn-hover-border-color: var(--bg-bd-primary-dark)!important;
  --bs-btn-focus-shadow-rgb: var(--bg-bd-primary)!important;
  --bs-btn-active-color: var(--bs-white)!important;
  --bs-btn-active-bg: var(--bg-bd-primary)!important;
  --bs-btn-active-border-color: var(--bg-bd-primary)!important;
  --bs-btn-disabled-color: #fff!important;
  --bs-btn-disabled-bg: var(--bg-bd-primary-light)!important;
  --bs-btn-disabled-border-color: var(--bg-bd-primary-light)!important;
}

.text-bs-primary{
  color: #6eb446!important;
}

.bg-bs-primary{
  background-color: #6eb446!important;
}

.border-bs-primary{
  border-color: #6eb446!important;
}
.border-top-bs-primary{
  border-top: #6eb446!important;
}
.border-bottom-bs-primary{
  border-bottom: #6eb446!important;
}
.border-right-bs-primary{
  border-right: #6eb446!important;
}
.border-left-bs-primary{
  border-left: #6eb446!important;
}

.form-check-input {
  clear: left;
}

.form-switch.form-switch-sm {
  margin-bottom: 0.5rem; /* JUST FOR STYLING PURPOSE */
}

.form-switch.form-switch-sm .form-check-input {
  height: 1rem;
  width: calc(1rem + 0.75rem);
  border-radius: 2rem;
}

.form-switch.form-switch-md {
  margin-bottom: 1rem; /* JUST FOR STYLING PURPOSE */
}

.form-switch.form-switch-md .form-check-input {
  height: 1.5rem;
  width: calc(2rem + 0.75rem);
  border-radius: 3rem;
}

.form-switch.form-switch-lg {
  margin-bottom: 1.5rem; /* JUST FOR STYLING PURPOSE */
}

.form-switch.form-switch-lg .form-check-input {
  height: 2rem;
  width: calc(3rem + 0.75rem);
  border-radius: 4rem;
}

.form-switch.form-switch-xl {
  margin-bottom: 2rem; /* JUST FOR STYLING PURPOSE */
}

.form-switch.form-switch-xl .form-check-input {
  height: 2.5rem;
  width: calc(4rem + 0.75rem);
  border-radius: 5rem;
}

.pointer{
  cursor: pointer;
}

/* .btn-bd-primary {
  --bs-btn-color: var(--bs-white)!important;
  --bs-btn-bg: var(--bs-purple)!important;
  --bs-btn-border-color: var(--bs-purple)!important;
  --bs-btn-border-radius: .5rem!important;
  --bs-btn-hover-color: var(--bs-white)!important;
  --bs-btn-hover-bg: var(--bs-indigo)!important;
  --bs-btn-hover-border-color: var(--bs-indigo)!important;
  --bs-btn-focus-shadow-rgb: var(--bs-purple-rgb)!important;
  --bs-btn-active-color: var(--bs-btn-hover-color)!important;
  --bs-btn-active-bg: var(--bs-purple)!important;
  --bs-btn-active-border-color: var(--bs-purple)!important;
  --bs-btn-disabled-color: #fff!important;
  --bs-btn-disabled-bg: #570368!important;
  --bs-btn-disabled-border-color: #430350!important;
} */

.text-indigo{
  color: var(--bs-indigo)!important;
}

.bg-indigo{
  background-color: var(--bs-indigo)!important;
}

.circle{
  top: 0;
  right: 0;
  width: 1.4rem;
  height: 1.4rem;
  background-color: red;
  border-radius: 100%;
}

.grecaptcha-badge { 
  visibility: hidden;
}

/*Datatables*/
.rdt_TableCell, .rdt_ExpanderRow, .rdt_TableRow > div{
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  flex-direction: column;
  padding-top: .5rem;
}
.rdt_TableRow > div > button > svg{
  color: var(--bs-emphasis-color) !important;
}
.rdt_ExpanderRow{
  padding: 0;
}

.rdt_TableHeadRow > div {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
  --bs-bg-opacity: 1;
  background-color: var(--bg-bd-primary)!important;
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;

}

/* .rdt_TableCol{ */
  /* --bs-text-opacity: 1;
  color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
  --bs-bg-opacity: 1;
  background-color: var(--bg-bd-primary)!important;
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; */
/* } */

.rdt_Pagination{
  --bs-text-opacity: 1;
  color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
  --bs-bg-opacity: 1;
  background-color: var(--bg-bd-primary)!important;
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.heigths-pac-pro{
    height: 130px;
}

@media (min-width: 768px) {
    .heigths-pac-pro{
        height: 110px;
    }
}
@media (min-width: 851px) {
    .heigths-pac-pro{
        height: 100px;
    }
}
@media (min-width: 992px) {
    .heigths-pac-pro{
        height: 85px;
    }
}
.react-datepicker-popper{
  z-index: 10001!important;
}

.select-search-container:not(.select-search-has-focus) .select-search-select {
  display: none!important;
}

.size{
  border: #000 solid 1px ;
  border-radius: 1px;
  width: 5rem;
  height: 8rem;
  background-color: rgb(166, 166, 229)

}

.welcome{
  animation: myAnim 5s ease 0s 1 normal forwards;
}

.prohibed{
  cursor:not-allowed;
}

@keyframes myAnim {
	0% {
		transform: scale(0.5);
	}

	100% {
		transform: scale(1);
	}
}
.icon-dashboard:hover{
  transform: scale(1.1);
}
.icon-dashboard{
  color: rgba(0,0,0,.15);
  right: 10px;
  top: 25%;
  transition: all .4s linear;
}

/* 404 Error Page */
#oopss {
  position: fixed;
  left: 0px;
  top: 0;
  width: 100%;
  height: 100%;
  line-height: 1.5em;
  z-index: 10000;
}

#error-text {
  font-family: 'Noto Sans'!important;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nf-img {
  margin: 0px auto 0px;
  height: 300px;
}

.back {
  background: #fff;
  color: #000;
  font-size: 30px;
  text-decoration: none;
  margin: 1em auto 0;
  padding: .7em 2em;
  border-radius: 500px;
  box-shadow: 0 20px 70px 4px rgba(0, 0, 0, 0.1), inset 7px 33px 0 0px #6dc0ae;
  font-weight: 900;
  transition: all 300ms ease; 
}

.back::hover {
  transform: translateY(-13px);
  box-shadow: 0 35px 90px 4px rgba(0,0,0, .3), inset 0px 0 0 3px #000;
}

.text-justify {
  text-align: justify;
  text-justify: inter-word;
}

.w-150{
  width: 175px!important;
}


.files2 {
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
  transition: outline-offset .15s ease-in-out, background-color .15s linear;
  text-align: center !important;
  margin: 0;
  width: 100% !important;
  background-color: #c8c4c4;
}
.files input {
  opacity: 0!important;
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
  transition: outline-offset .15s ease-in-out, background-color .15s linear;
  padding: 120px 0px 85px 35%;
  text-align: center !important;
  margin: 0;
  width: 100% !important;
}
.files input:focus{     outline: 2px dashed #92b0b3;  outline-offset: -10px;
  -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
  transition: outline-offset .15s ease-in-out, background-color .15s linear; border:1px solid #92b0b3;
}
.files{ position:relative}
.files:after {  
  pointer-events: none;
  position: absolute;
  top: 60px;
  left: 0;
  width: 90px;
  right: 0;
  height: 100px;
  content: " ";
  background-image: url("./img/upload.svg");
  display: block;
  margin: 0 auto;
  background-size: 100%;
  background-repeat: no-repeat;
  opacity: 0.6;
}
.color input{ background-color:#f1f1f1;}
.files:before {
  position: absolute;
  bottom: 10px;
  left: 0;  pointer-events: none;
  width: 100%;
  right: 0;
  height: 57px;
  content: "Upload your file here. ";
  display: block;
  margin: 0 auto;
  color: #000000;
  font-weight: bold;
  text-transform: capitalize;
  text-align: center;
}

html[data-bs-theme="dark"] .title {
  color: white;
}

html[data-bs-theme="light"] .title {
  color: #475569;
}
